<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto h-full" @close="open = false">
			<div class="flex items-end justify-center text-center">
				<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
					leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild as="template" enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
					<div
						class="fixed top-0 sm:top-8 inline-block sm:rounded-md h-screen sm:h-7/8 align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-full ">
						<div class="fixed w-auto top-4 right-3 z-10">
							<div class="hidden sm:flex justify-end w-full">
								<Icon name="XIcon" size="6" isOutline color="gray-600" @click="open = false" :onClick="onNoClick" />
							</div>
						</div>
								<div class="flex flex-col h-full overflow-y-auto sm:p-6 px-4">
									<div class="flex items-center mt-2 justify-between">
										<div class="flex">
											<h1 class="text-gray-900">{{title}}</h1>
										</div>
										<div class="flex h-full sm:hidden mt-2 ml-2 justify-start">
											<Icon name="XIcon" size="8" isOutline color="gray-600" @click="open = false" :onClick="onNoClick" />
										</div>
									</div>
									<div class="mt-3 h-screen w-auto text-left">
										<div class="text-lg mb-6" v-html="textHtml">
										</div>
										<div class="text-xl font-bold">
											{{videoTitle}}
										</div>
		<!--								<div class="w-auto h-96 bg-gray-300 mt-2">-->
		<!--								</div>-->
										<video ref="videoPlayer" class="video-js w-full h-56 sm:h-96 vjs-big-play-centered"></video>
									</div>
								</div>
							
						<div class="fixed w-full bottom-0 bg-gray-100 h-auto z-20">
							<div class="flex flex-row items-end justify-end my-4 mr-4 sm:mr-3 lg:mr-6">
								<Button
										@click="() => open = false"
										:content="activeIndex === 0 ? 'Skip' : 'Back'"
										variant="secondary"
										:is-no-hover-border="true"
										customClass=" px-2"
								/>
								<Button
										@click="() => {open = false; $emit('onCreateVenture')}"
										:content="activeIndex === 0 ? 'Add an Idea' : 'Next'"
										variant="primary"
										customClass="ml-4 px-4"
										:isLoading="isLoading"
								/>
							</div>
						</div>
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Button from '../../../atoms/Button/Button.vue'
import Text from '../../../atoms/Text/Text.vue'
import videojs from 'video.js';
import 'video.js/dist/video-js.css'
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Controller} from 'swiper';
import 'swiper/swiper.min.css';
import {ref} from "vue";
import Input from '../../../molecules/Inputs/Component/Component.vue'
import Textarea from '../../../molecules/Textareas/Simple/Simple'
/**
 * - Use it to show a simple modal.
 */
export default {
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		Icon, Button, Text,
		Swiper,
		SwiperSlide,
		Input, Textarea,
	},
	data() {
		const swiper = ref(null);
		const setSwiper = (e) => {
			swiper.value = e;
		};
		return {
			player: null,
			setSwiper,
			Controller,
			swiper,
			activeIndex: 0,
			showSwiper: false,
			options: {
				autoplay: false,
				loop: false,
				controls: true,
				poster: '',
				sources: [
					{
						src: 'https://dscvry-staging-media.s3.amazonaws.com/dscvry-welcome.mp4',
						type: 'video/mp4'
					}
				]
			},
			isFocusFirst: true,
			isFocusSecond: true,
			name: null,
			description: null,
		}
	},
	watch: {
		open: function (val) {
			console.log(val, 'val')
			if (val) {
				setTimeout(() => this.showSwiper = true, 200)
			} else {
				this.$emit('onClose')
			}
		}
	},
	methods: {
		onNext(index) {
			this.swiper.slideTo(index + 1);
			this.activeIndex = index + 1;
		},
		onBack(index) {
			this.swiper.slideTo(index - 1);
			this.activeIndex = index - 1;
		},
		getHtml() {
			return `I'm thinking about creating <span class="font-bold">${this.description}</span>. ${this.name && !this.isFocusSecond ? `For now, I'm going to call it "<span class="font-semibold">${this.name}</span>"` : ''}`
		}
	},
	props: {
		/**
		 * Use it for modal title
		 */
		title: {
			type: String,
			default: ''
		},
		/**
		 * Use it for modal description
		 */
		textHtml: {
			type: String,
			default: ''
		},
		/**
		 * Use it for yes button text
		 */
		open: {
			type: Boolean,
			default: true
		},
		/**
		 * Use it for modal title
		 */
		videoUrl: {
			type: String,
			default: ''
		},
		/**
		 * Use it for modal title
		 */
		videoTitle: {
			type: String,
			default: ''
		},
		/**
		 * Use it for no button text
		 */
		noBtnText: {
			type: String,
			default: ''
		},
		/**
		 * Use it for no buttona action
		 */
		onNoClick: {
			type: Function,
			default: () => {}
		},
		content: {
			type: Object,
			default: () => {}
		}
	},
	mounted() {
		if (this.open) {
			setTimeout(() => this.showSwiper = true, 200)
		}
		setTimeout(() => {
			if (this.$refs.videoPlayer) {
				this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
					console.log('onPlayerReady', this);
				});
			}
		}, 1000)
	},
	beforeUnmount() {
		if (this.player) {
			this.player.dispose()
		}
	}
}
</script>
