<template>
    <TransitionRoot as="template" :show="open">
        <Dialog
            as="div"
            class="fixed z-10 inset-0 overflow-y-auto h-full"
            @close="
                () => {
                    open = false;
                    $emit('onClose');
                }
            "
        >
            <div class="flex items-end justify-center text-center">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay
                        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="fixed top-0 sm:top-8 inline-block sm:rounded-md h-screen sm:h-7/8 align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-4xl sm:w-full"
                    >
                        <div class="fixed w-auto top-4 right-3 z-10">
                            <div class="hidden sm:flex justify-end w-full">
                                <Icon
                                    name="XIcon"
                                    size="6"
                                    isOutline
                                    color="gray-600"
                                    @click="
                                        () => {
                                            open = false;
                                            $emit('onClose');
                                        }
                                    "
                                    :onClick="onNoClick"
                                />
                            </div>
                        </div>
                        <div
                            class="flex flex-col h-full overflow-y-auto sm:p-6 px-4"
                        >
                            <div class="flex items-center mt-2 justify-between">
                                <div class="flex mt-4">
                                    <h1 class="text-gray-900 text-3xl">
                                        {{ title }}
                                    </h1>
                                </div>
                                <div
                                    class="flex h-full sm:hidden mt-2 ml-2 justify-start"
                                >
                                    <Icon
                                        name="XIcon"
                                        size="8"
                                        isOutline
                                        color="gray-600"
                                        @click="
                                            () => {
                                                open = false;
                                                $emit('onClose');
                                            }
                                        "
                                    />
                                </div>
                            </div>
                            <div class="mt-3 h-screen w-auto text-left">
                                <div
                                    class="text-md mb-6"
                                    v-html="textHtml"
                                ></div>
                                <div class="bg-gray-100 px-6 py-3">
                                    <Text
                                        content="Your ideas (pick one)"
                                        weight="bold"
                                        color="black"
                                        size="lg"
                                        customClass="mb-3"
                                    />
                                    <div class="border-t border-b divide-y">
                                        <div
                                            v-for="venture in ventures"
                                            :key="venture.id"
                                            class="grid grid-cols-4 bg-white py-2 px-4"
                                        >
                                            <div class="col-span-2">
                                                {{ venture && venture.name }}
                                            </div>
                                            <div class="col-span-2">
                                                {{
                                                    venture &&
                                                    venture.description
                                                }}
                                            </div>
                                            <div class="absolute right-16">
                                                <Toggle
                                                    :value="venture.isToggled"
                                                    @update:value="
                                                        (val) =>
                                                            onUpdateToggle(
                                                                val,
                                                                venture
                                                            )
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <Text
                                        content="+ Add New"
                                        color="primary-600"
                                        class="flex mt-4 ml-4 sm:ml-0 underline cursor-pointer"
                                        hoverColor="primary-900"
                                        size="sm"
                                        weight="medium"
                                        @click="
                                            () => {
                                                open = false;
                                                $emit('onCreateVenture');
                                            }
                                        "
                                    />
                                </div>
                                <div class="text-md mb-6 italic mt-5">
                                    {{ text }}
                                </div>
                            </div>
                        </div>
                        <div
                            class="fixed w-full bottom-0 bg-gray-100 h-auto z-20"
                        >
                            <div
                                class="flex flex-row items-end justify-end my-4 mr-4 sm:mr-3 lg:mr-6"
                            >
                                <Button
                                    content="Cancel"
                                    :variant="null"
                                    customClass="bg-white text-black underline px-2 bg-gray-100"
                                    @click="
                                        () => {
                                            open = false;
                                            $emit('onClose');
                                        }
                                    "
                                />
                                <Button
                                    @click="
                                        () => {
                                            open = false;
                                            $emit('onClose');
                                        }
                                    "
                                    content="Next"
                                    variant="primary"
                                    customClass="ml-4 px-4"
                                    :isLoading="isLoading"
                                />
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import Button from "../../../atoms/Button/Button.vue";
import Text from "../../../atoms/Text/Text.vue";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Controller } from "swiper";
import "swiper/swiper.min.css";
import { ref } from "vue";
import Input from "../../../molecules/Inputs/Component/Component.vue";
import Textarea from "../../../molecules/Textareas/Simple/Simple";
import Toggle from "../../../molecules/Toggles/Simple/Simple";

export default {
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        Icon,
        Button,
        Text,
        Swiper,
        SwiperSlide,
        Input,
        Textarea,
        Toggle,
    },
    data() {
        const swiper = ref(null);
        const setSwiper = (e) => {
            swiper.value = e;
        };
        return {
            player: null,
            setSwiper,
            Controller,
            swiper,
            activeIndex: 0,
            showSwiper: false,
            isFocusFirst: true,
            isFocusSecond: true,
            name: null,
            description: null,
        };
    },
    watch: {
        open: function (val) {
            if (val) {
            } else {
                this.$emit("onClose");
            }
        },
    },
    methods: {
        onUpdateToggle(val, venture) {
            console.log(val);
            venture.isToggled = val;
            if (val === true) {
                this.actions.venture.addVentureTo({
                    userId: this.state.currentUser?.id,
                    ventureId: venture?.id,
                    cohortId: this.state.currentUser?.cohorts?.[0]?.id,
                });
            } else {
                this.actions.venture.removeVentureFrom({
                    userId: this.state.currentUser?.id,
                    ventureId: venture?.id,
                    cohortId: this.state.currentUser?.cohorts?.[0]?.id,
                });
            }
        },
    },
    props: {
        /**
         * Use it for modal title
         */
        title: {
            type: String,
            default: "",
        },
        /**
         * Use it for modal description
         */
        textHtml: {
            type: String,
            default: "",
        },
        /**
         * Use it for yes button text
         */
        open: {
            type: Boolean,
            default: true,
        },
        /**
         * Use it for modal title
         */
        videoUrl: {
            type: String,
            default: "",
        },
        /**
         * Use it for modal title
         */
        videoTitle: {
            type: String,
            default: "",
        },
        /**
         * Use it for no button text
         */
        noBtnText: {
            type: String,
            default: "",
        },
        /**
         * Use it for no buttona action
         */
        onNoClick: {
            type: Function,
            default: () => {},
        },
        text: {
            type: String,
            default: "",
        },
        ventures: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
