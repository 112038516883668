<template>
	<div class="flex justify-between">
		<div class="w-3/4 space-y-2">
			<Text color="black" weight="bold" size="xl" class="sm:text-3xl" :content="title"/>
			<Text color="black" weight="medium" size="md" :content="description"/>
		</div>
		<Avatar size="14" :src="profile.avatar" :username="profile.name" :title="profile.name"/>
	</div>
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
import Avatar from '../../../atoms/Avatar/Avatar.vue'

export default {
	components: {
		Text,
		Avatar,
	},
	name: "ListViewItem",
	props: {
		title: {
			type: String,
			default: ""
		},
		description: {
			type: String,
			default: '',
		},
		profile: {
			type: Object,
			default: () => {
			}
		}
	}

}
</script>
